import { getKeyAnswer } from "utils";
import "./answerDetail.css";

const AnswerDetail = ({ data }) => {
  let className = "answer-view";
  if (data.isCorrect) {
    className += " correct";
  } else {
    if (data.isSelected) {
      className += " wrong";
    }
  }

  return (
    <div className={className}>
      <span>
        <b>{getKeyAnswer(data.sort)}. </b>
      </span>
      <span>{data.value}</span>
    </div>
  );
};

export default AnswerDetail;
