import axios from "axios";
import { logDebug, toast } from "utils";
import { localStorageHelper } from "utils/localStorageHelper";
import { ApiMethod, MessageType, StorageKey } from "utils/const";

export const baseUrl = process.env.REACT_APP_API_URL;

const getUrl = (baseUrl, endpoint = "") => {
  if (!endpoint.startsWith("/")) {
    endpoint = "/" + endpoint;
  }
  return `${baseUrl || process.env.REACT_APP_API_URL}${endpoint}`;
};

/** Sử dụng cho RTK Query. */
const callApi = async ({
  baseUrl,
  endpoint,
  method,
  data,
  params,
  isAuthorized
}) => {
  const token = localStorageHelper.get(StorageKey.token);
  const headers = {};
  if (isAuthorized) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const config = {
    url: getUrl(baseUrl, endpoint),
    method,
    data,
    headers,
    params
  };
  try {
    const response = await axios(config);
    return { data: response.data };
  } catch (apiError) {
    if (apiError.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx.
      logDebug("server_error", apiError.response);
      let error = {
        code: "server_error",
        message: "Có lỗi xảy ra, vui lòng thử lại sau"
      };
      if (apiError.response?.data) {
        error = apiError.response.data;
      }
      return { error };
    } else if (apiError.request) {
      // The request was made but no response was received.
      logDebug("no_response", apiError.request);
      return {
        error: {
          code: "no_response",
          message: "Có lỗi xảy ra, vui lòng thử lại sau"
        }
      };
    } else {
      // Something happened in setting up the request that triggered an Error.
      logDebug("internal_error", apiError.message);
      return {
        error: {
          code: "internal_error",
          message: "Có lỗi xảy ra, vui lòng thử lại sau"
        }
      };
    }
  }
};

export const baseApi =
  ({ baseUrl }) =>
  async ({
    endpoint,
    method = ApiMethod.get,
    data = null,
    params = null,
    isAuthorized = true,
    notify = true
  }) => {
    const response = await callApi({
      baseUrl,
      endpoint,
      method,
      data,
      params,
      isAuthorized
    });

    // Toast response.
    if (notify) {
      let message = response.data?.message || response.error?.message;
      if (message) {
        const type = response.error ? MessageType.error : MessageType.success;
        toast(message, type);
      }
    }

    return response;
  };
