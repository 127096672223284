import { Layout, Typography } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Urls } from "utils/const";
import { DEFAULT_TEXT_COLOR, mixin } from "utils/uiConst";

const { Footer: AntdFooter } = Layout;
const { Title, Text } = Typography;

const StyledFooter = styled(AntdFooter)`
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .span {
    text-transform: uppercase;
  }

  @media (${mixin.to.sm}) {
    padding: 24px 10px !important;
  }
`;

const StyledPhone = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  justify-content: center;
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Title level={3} style={{ color: DEFAULT_TEXT_COLOR, fontSize: 23 }}>
        TRUNG TÂM NGOẠI NGỮ ULUS
      </Title>
      <Text>Đường 4b, Thôn 4, Xã Bom Bo, Huyện Bù Đăng - Tỉnh Bình Phước</Text>

      <StyledPhone>
        <Text>Điện thoại: </Text>
        <Text>
          <a href="tel:0979911393">097 991 13 93</a> (Thầy Hùng) -
        </Text>
        <Text>
          <a href="tel:0366220096">036 622 0096</a> (Cô Hằng)
        </Text>
      </StyledPhone>

      <Text style={{ textTransform: "initial" }}>
        Email: <a href="mailto:ttnnulus@gmail.com">ttnnulus@gmail.com</a>
      </Text>

      <Text style={{ textTransform: "initial" }}>
        <Link to={Urls.policy}>Chính sách bảo mật (Privacy Policy)</Link>
      </Text>
    </StyledFooter>
  );
};

export default Footer;
