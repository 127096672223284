import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { ApiMethod } from "utils/const";
import { baseApi, baseUrl } from "./baseApiService";

export const authService = createApi({
  reducerPath: "auth",
  baseQuery: baseApi({ baseUrl }),
  endpoints: builder => ({
    login: builder.mutation({
      query: data => ({ endpoint: "auth/login", method: ApiMethod.post, data })
    }),
    oauth: builder.mutation({
      query: data => ({ endpoint: "auth/oauth", method: ApiMethod.post, data })
    })
  })
});
