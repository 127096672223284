import FacebookLogin from "@greatsumini/react-facebook-login";
import { Button } from "antd";
import { useEffect } from "react";
import { authService } from "services";
import { OAuthProvider, StorageKey } from "utils/const";
import { localStorageHelper } from "utils/localStorageHelper";

const FacebookLoginButton = ({ onSuccess }) => {
  const [loginOAuth, loginOAuthResp] = authService.useOauthMutation();

  useEffect(() => {
    if (loginOAuthResp.isSuccess && loginOAuthResp.data) {
      const { token } = loginOAuthResp.data;
      localStorageHelper.set(StorageKey.token, token);

      if (onSuccess) {
        onSuccess();
      }
    }
  }, [loginOAuthResp]);

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
      onFail={error => {
        console.log("Login Failed!", error);
      }}
      onProfileSuccess={response => {
        const body = {
          name: response.name,
          email: response.email,
          picture: response.picture.data.url,
          id: response.id,
          emailVerified: true,
          provider: OAuthProvider.facebook
        };
        loginOAuth(body);
      }}
      render={({ onClick }) => (
        <Button
          type="primary"
          ghost
          htmlType="button"
          onClick={onClick}
          icon={<img src="icons/facebook.svg" />}
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            width: "100%",
            justifyContent: "center"
          }}
          loading={loginOAuthResp.isLoading}
        >
          Đăng nhập bằng Facebook
        </Button>
      )}
    />
  );
};

export default FacebookLoginButton;
