import { Empty as AntEmpty } from "antd";

const Empty = () => (
  <AntEmpty
    description={<span>Không có dữ liệu</span>}
    image={AntEmpty.PRESENTED_IMAGE_SIMPLE}
  />
);

export default Empty;
