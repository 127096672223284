import styled from "styled-components";
import { Layout, Menu } from "antd";
import { DEFAULT_TEXT_COLOR, mixin } from "utils/uiConst";
const { Header, Sider } = Layout;

export const StyledHeader = styled(Header)`
  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: #fff !important;
  padding: 0 10px !important;
  height: 65px !important;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
`;

export const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLogoContainer = styled.div`
  line-height: 0;

  img {
    border-radius: 0.5rem;
  }
`;

export const StyledMainLayout = styled(Layout)`
  .content {
    margin-top: 75px;
    overflow: initial;

    .breadcrumb {
      margin: 16px 0 !important;
    }
  }

  .footer {
    text-align: center;
  }
`;

export const StyledMain = styled.div`
  /* background: #fff; */
  /* min-height: 360px; */
  /* margin: 12px 24px; */
  /* padding: 10px 20px; */

  @media (${mixin.to.md}) {
    margin: 0;
  }
`;

export const StyledMainInner = styled.div`
  @media (${mixin.from.xl}) {
    max-width: 990px;
    margin-left: auto;
    margin-right: auto;
  }
`;
export const StyledSider = styled(Sider)`
  margin-top: 65px;
  overflow: auto;
  height: calc(100vh - 65px);
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  border-right: 1px solid rgba(5, 5, 5, 0.06);
`;

export const StyledTrigger = styled.span`
  /* margin-left: 25px; */
  cursor: pointer;
  transition: color 0.3s;
  font-size: 1.5rem;

  &:hover {
    color: #1890ff;
  }
`;

export const StyledMenu = styled(Menu)`
  font-size: 1.4rem;

  .anticon {
    font-size: 1.4rem !important;
  }
`;
