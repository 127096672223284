import {
  ClockCircleOutlined,
  QuestionCircleOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Card, Col, Row, Statistic, Typography } from "antd";
import { useGetUserName } from "hooks";

const { Title } = Typography;

const QuizInformation = ({ quiz }) => {
  const getUserName = useGetUserName();

  return (
    <Card>
      <div style={{ textAlign: "center" }}>
        <Title>{quiz.name}</Title>
        <Title level={2}>{quiz.description}</Title>
      </div>
      <Row gutter={[16, 8]}>
        <Col md={8} sm={12} span={24}>
          <Statistic
            prefix={<ClockCircleOutlined />}
            value={quiz.time}
            suffix="phút"
          />
        </Col>

        <Col md={8} sm={24}>
          <Statistic
            prefix={<QuestionCircleOutlined />}
            value={quiz.questions.length}
            suffix="câu hỏi"
          />
        </Col>
        
        <Col md={8} sm={24}>
          <Statistic
            prefix={<UserOutlined />}
            value={getUserName(quiz.createdBy)}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default QuizInformation;
