import { Layout, Loading } from "components/commons";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Urls } from "utils/const";

const ExamList = lazy(() => import("pages/examList"));
const DoingExam = lazy(() => import("pages/doingExam"));

const Home = lazy(() => import("pages/home"));
const Login = lazy(() => import("pages/login"));
const Contact = lazy(() => import("pages/contact"));
const PrivacyPolicy = lazy(() => import("pages/privacyPolicy"));
const DeleteAccountGuide = lazy(() => import("pages/guides/delete-account"));
const About = lazy(() => import("pages/about"));
const NotFound = lazy(() => import("pages/notfound"));

const QuizResults = lazy(() => import("pages/quizResults"));
const QuizResultDetail = lazy(() => import("pages/quizResults/detail"));

const Posts = lazy(() => import("pages/posts"));
const Post = lazy(() => import("pages/posts/post"));

const Courses = lazy(() => import("pages/courses"));
const Course = lazy(() => import("pages/courses/course"));

const UserProfile = lazy(() => import("pages/users/profile"));

const RouteURL = () => (
  <Suspense fallback={<Loading isLoading />}>
    <Routes>
      <Route path={Urls.login} element={<Login />} />

      <Route path="" element={<Layout />}>
        <Route path="/" element={<Home />} />

        <Route path={Urls.courses} element={<Courses />} />
        <Route path={Urls.courses + "/:url"} element={<Course />} />

        <Route path={Urls.posts} element={<Posts />} />
        <Route path={Urls.posts + "/:url"} element={<Post />} />

        <Route path={Urls.contact} element={<Contact />} />

        <Route path={Urls.about} element={<About />} />

        <Route path={Urls.policy} element={<PrivacyPolicy />} />

        <Route
          path={Urls.deleteAccountGuide}
          element={<DeleteAccountGuide />}
        />

        <Route path="*" element={<NotFound />} />
      </Route>

      <Route element={<Layout needAuth />}>
        <Route path={Urls.exams}>
          <Route path="" element={<ExamList />} />
          <Route path=":id?" element={<DoingExam />} />
        </Route>

        <Route path={Urls.quizResults}>
          <Route path="" element={<QuizResults />} />
          <Route path=":id?" element={<QuizResultDetail />} />
        </Route>

        <Route path={Urls.profile} element={<UserProfile />} />
      </Route>
    </Routes>
  </Suspense>
);

export default RouteURL;
