import { Alert } from "antd";
import { useCountdown } from "hooks";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { Urls } from "utils/const";

const StyledAlert = styled(Alert)`
  justify-content: center;

  .ant-alert-content {
    flex: unset;
  }
`;

const RedirectLoginCountdown = ({ seconds, from }) => {
  const { counter } = useCountdown(seconds);

  if (counter === 0) {
    return <Navigate to={Urls.login} state={{ from }} replace />;
  }

  return (
    <StyledAlert
      showIcon
      type="warning"
      message={`Nội dung cần đăng nhập để xem, sẽ chuyển sang trang đăng nhập sau ${counter} giây...`}
    />
  );
};

export default RedirectLoginCountdown;
