import { GoogleOAuthProvider } from "@react-oauth/google";
import { ConfigProvider } from "antd";
import viVN from "antd/locale/vi_VN";
import { Empty } from "components/commons";
import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { userService } from "services";
import { Urls } from "utils/const";
import { DEFAULT_TEXT_COLOR, FONT_FAMILY } from "utils/uiConst";
import RouteURL from "./routes";

function App() {
  const [getMySelf] = userService.useLazyGetMySelfQuery();

  useEffect(() => {
    if (window.location.pathname !== Urls.login) {
      getMySelf();
    }
  }, []);

  return (
    <ConfigProvider
      locale={viVN}
      renderEmpty={() => <Empty />}
      theme={{
        token: { fontFamily: FONT_FAMILY },
        components: {
          Menu: { itemColor: DEFAULT_TEXT_COLOR }
        }
      }}
    >
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Router>
          <RouteURL />
        </Router>
      </GoogleOAuthProvider>
    </ConfigProvider>
  );
}

export default App;
