import Icon, {
  ContactsOutlined,
  HomeOutlined,
  ReadOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";

export const Menus = [
  {
    key: "home",
    label: <Link to="/">Trang chủ</Link>,
    icon: <HomeOutlined />,
    sort: 1
  },
  {
    key: "courses",
    label: "Khóa học",
    icon: <ReadOutlined />,
    children: [
      {
        key: "kindergarten",
        label: <Link to="/khoa-hoc/tieng-anh-mam-non">Tiếng Anh mầm non</Link>
      },
      {
        key: "primary",
        label: <Link to="/khoa-hoc/tieng-anh-tieu-hoc">Tiếng Anh tiểu học</Link>
      },
      {
        key: "class",
        label: <Link to="/khoa-hoc/tieng-anh-theo-lop">Tiếng Anh theo lớp</Link>
      },
      {
        key: "communication",
        label: (
          <Link to="/khoa-hoc/tieng-anh-giao-tiep">Tiếng Anh giao tiếp</Link>
        )
      },
      {
        key: "testing",
        label: (
          <Link to="/khoa-hoc/tieng-anh-luyen-thi">Tiếng Anh luyện thi</Link>
        )
      },
      { key: "ielts", label: <Link to="/khoa-hoc/ielts">IELTS</Link> },
      { key: "toeic", label: <Link to="/khoa-hoc/toeic">TOEIC</Link> }
    ],
    sort: 2
  },
  {
    key: "quiz",
    label: "Góc tiếng anh",
    icon: <Icon component={() => <img src="/icons/menus/exam.svg" />} />,
    children: [
      { key: "exams", label: <Link to="/bai-thi">Bài thi</Link> },
      {
        key: "results",
        label: <Link to="/ket-qua-bai-thi">Kết quả bài thi</Link>
      }
    ],
    sort: 3
  },
  {
    key: "contact",
    label: <Link to="/lien-he">Liên hệ</Link>,
    icon: <ContactsOutlined />,
    sort: 4
  },
  {
    key: "about",
    label: <Link to="/gioi-thieu">Giới thiệu</Link>,
    icon: <Icon component={() => <img src="/icons/menus/introduce.svg" />} />,
    sort: 5
  }
];
