import { useGoogleOneTapLogin } from "@react-oauth/google";
import { useEffect } from "react";
import { authService, userService } from "services";
import { OAuthProvider, StorageKey } from "utils/const";
import { localStorageHelper } from "utils/localStorageHelper";

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const UseGoogleOneTapWrapper = () => {
  const [loginOAuth, loginOAuthResp] = authService.useOauthMutation();
  const [getMySelf, getMySelfResult] = userService.useLazyGetMySelfQuery();

  const onSuccess = resp => {
    const userInfo = parseJwt(resp.credential);
    const loginGoogleRequest = {
      name: userInfo.name,
      email: userInfo.email,
      picture: userInfo.picture,
      id: userInfo.sub,
      emailVerified: userInfo.email_verified,
      provider: OAuthProvider.google
    };
    loginOAuth(loginGoogleRequest);
  };

  useGoogleOneTapLogin({
    onSuccess,
    onError: () => console.log("Login Google failed")
  });

  useEffect(() => {
    if (loginOAuthResp.isSuccess && loginOAuthResp.data) {
      const { token } = loginOAuthResp.data;
      localStorageHelper.set(StorageKey.token, token);

      afterLoginActions();
    }
  }, [loginOAuthResp]);

  useEffect(() => {
    if (getMySelfResult.isSuccess) {
      const user = getMySelfResult.currentData;
      localStorageHelper.set(StorageKey.user, user);
    }
  }, [getMySelfResult]);

  const afterLoginActions = () => {
    getMySelf();
  };

  return null;
};

const GoogleOneTap = () => {
  const {
    currentData: user,
    isLoading,
    isFetching,
    isSuccess
  } = userService.endpoints.getMySelf.useQueryState();

  if (isLoading || isFetching || (isSuccess && user)) {
    return null;
  }

  return <UseGoogleOneTapWrapper />;
};

export default GoogleOneTap;
