import { ApiMethod } from "utils/const";
import { baseApi, baseUrl } from "./baseApiService";
import { createApi } from "@reduxjs/toolkit/query/react";

export const userService = createApi({
  reducerPath: "user",
  baseQuery: baseApi({ baseUrl }),
  endpoints: builder => ({
    getMySelf: builder.query({
      query: () => ({ endpoint: "users/myself", notify: false })
    }),
    getCompactUsers: builder.query({
      query: () => ({ endpoint: "users/compact-users" })
    }),
    updateProfile: builder.mutation({
      query: data => ({
        endpoint: "users/myself",
        method: ApiMethod.put,
        data
      })
    }),
    changePassword: builder.mutation({
      query: data => ({
        endpoint: "users/password",
        method: ApiMethod.post,
        data
      })
    })
  })
});
