import { List, Alert } from "antd";
import AnswerDetail from "../answerDetail";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  StyledQuestion,
  StyledQuestionOrder,
  StyledQuestionTitle
} from "./styles";
import { ERROR_COLOR, SUCCESS_COLOR } from "utils/uiConst";
import { createMarkup } from "utils";
import { StyledPostWrapper } from "utils/globalStyle";

const iconStyle = {
  fontSize: "32px"
};

const successIconStyle = {
  ...iconStyle,
  color: SUCCESS_COLOR
};

const errorIconStyle = {
  ...iconStyle,
  color: ERROR_COLOR
};

const QuestionDetail = ({ answers, sort, title, point }) => {
  const correct = answers.some(answer => answer.isCorrect && answer.isSelected);
  const answered = answers.some(answer => answer.isSelected);

  return (
    <StyledQuestion>
      {!answered && (
        <Alert
          type="warning"
          showIcon
          message="Câu hỏi chưa được trả lời!"
          style={{ marginBottom: "4px" }}
        />
      )}

      <StyledQuestionTitle>
        <StyledQuestionOrder>
          {correct ? (
            <CheckCircleOutlined style={successIconStyle} />
          ) : (
            <CloseCircleOutlined style={errorIconStyle} />
          )}
          <span>
            Câu hỏi số {sort}.{" "}
            <span style={{ color: "red" }}>({point} điểm)</span>
          </span>
        </StyledQuestionOrder>

        <StyledPostWrapper>
          <div dangerouslySetInnerHTML={createMarkup(title)} />
        </StyledPostWrapper>
      </StyledQuestionTitle>

      <List
        dataSource={answers}
        split={false}
        renderItem={answer => (
          <List.Item>
            <AnswerDetail
              data={answer}
              // isSelect={selectedAnswer && selectedAnswer.id === answer.id}
            />
          </List.Item>
        )}
      />
    </StyledQuestion>
  );
};

export default QuestionDetail;
