import SecureLS from "secure-ls";

const mySecret = process.env.REACT_APP_STORAGE_SECRET;

const securer = new SecureLS({
  encodingType: "aes",
  encryptionSecret: mySecret
});

export const localStorageHelper = {
  get: (key = "") => {
    try {
      const data = securer.get(key);
      if (data) {
        return data;
      } else {
        return undefined;
      }
    } catch (error) {
      console.log("error when get local storage", error);
      return undefined;
    }
  },
  set: (key, value) => {
    securer.set(key, value);
  },
  remove: key => securer.remove(key)
};
