import {
  authService,
  courseService,
  postService,
  quizResultService,
  quizService,
  userService
} from "services";
import uiReducer from "./uiReducer";

const rootReducer = {
  [authService.reducerPath]: authService.reducer,
  [courseService.reducerPath]: courseService.reducer,
  [quizService.reducerPath]: quizService.reducer,
  [quizResultService.reducerPath]: quizResultService.reducer,
  [postService.reducerPath]: postService.reducer,
  ui: uiReducer,
  [userService.reducerPath]: userService.reducer
};

export default rootReducer;
