import { useCallback, useEffect } from "react";
import { userService } from "services";

function useGetUserName() {
  const [getCompactUsers, getCompactUsersResult] =
    userService.useLazyGetCompactUsersQuery();

  const users = getCompactUsersResult.currentData || [];

  useEffect(() => {
    if (users.length === 0) {
      getCompactUsers();
    }
  }, []);

  return useCallback(
    id => users.find(u => u.id === id)?.fullName || id,
    [users]
  );
}

export { useGetUserName };
