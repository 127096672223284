import { message } from "antd";
import { MessageType } from "./const";
import dayjs from "dayjs";

const isDev = process.env.NODE_ENV !== "production";

export const logDebug = (key, data) => {
  if (isDev) {
    console.log(key, data);
  }
};

export const toast = (content, type) => {
  switch (type) {
    case MessageType.success:
      message.success(content);
      break;

    case MessageType.error:
      message.error(content);
      break;

    case MessageType.warn:
      message.warn(content);
      break;

    case MessageType.info:
      message.info(content);
      break;

    default:
      message.info(content);
      break;
  }
};

export function createMarkup(content) {
  return { __html: content };
}

const datetimeFormat = "DD/MM/YYYY HH:mm";
export const formatDateTime = date =>
  date ? dayjs(date).format(datetimeFormat) : "";

export const makeSearchParams = (p = {}) => new URLSearchParams(p).toString();

export const getIdFromUrl = (url = "") => {
  if (url) {
    const splitted = url.split("-id");
    if (splitted.length > 0) {
      const id = splitted[splitted.length - 1];
      return Number.parseInt(id);
    }
  }

  return 0;
};

/** Chuyển thứ tự sang key A, B, C, D,... của câu trả lời. `sort` bắt đầu từ `1`. */
export const getKeyAnswer = sort => {
  if (sort <= 0) {
    return "";
  }
  // 65 là "A" trong ascii.
  return String.fromCharCode(sort + 65 - 1);
};

export const copyToClipboard = value => {
  const textArea = document.createElement("textarea");
  textArea.value = value;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("Copy");
  textArea.remove();
};
