import { Image } from "antd";
import {
  StyledHeader,
  StyledHeaderContent,
  StyledLogoContainer,
  StyledMenu,
  StyledTrigger
} from "./styles";
import RightHeader from "./rightHeader";
import { useDispatch, useSelector } from "react-redux";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { uiAction } from "redux/actions";
import { useIsMobile } from "hooks";
import { Link } from "react-router-dom";

const Logo = (
  <StyledLogoContainer>
    <Link to="/">
      <Image
        alt="logo"
        src="/logo.png"
        preview={false}
        width={60}
        height={55}
      />
    </Link>
  </StyledLogoContainer>
);

const Header = ({ menus }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { collapsed } = useSelector(state => state.ui);

  const toggleCollapse = () => {
    dispatch(uiAction.toggleMenu());
  };

  return (
    <StyledHeader>
      <StyledHeaderContent>
        {isMobile ? (
          <>
            <StyledTrigger onClick={toggleCollapse}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </StyledTrigger>

            {Logo}

            <RightHeader />
          </>
        ) : (
          <>
            {Logo}

            <StyledMenu
              theme="light"
              mode="horizontal"
              style={{ minWidth: "0", flex: "auto" }}
              items={menus}
            />

            <div style={{ marginLeft: "auto" }}>
              <RightHeader />
            </div>
          </>
        )}
      </StyledHeaderContent>
    </StyledHeader>
  );
};

export default Header;
