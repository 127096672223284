import { Spin } from "antd";
import "./backdrop.css";

const Backdrop = ({ loading }) => {
  const className = loading ? "open" : "closed";

  return (
    <div className={"backdrop " + className}>
      <Spin spinning={loading} size="large" />
    </div>
  );
};

export default Backdrop;
