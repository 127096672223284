import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseApi, baseUrl } from "./baseApiService";

export const quizResultService = createApi({
  reducerPath: "quizResult",
  baseQuery: baseApi({ baseUrl }),
  endpoints: builder => ({
    getList: builder.query({
      query: params => ({ endpoint: "quiz-results/student", params })
    }),
    getById: builder.query({
      query: data => ({ endpoint: "quiz-results/" + data.id })
    })
  })
});
