import { createAction } from "@reduxjs/toolkit";
import { StorageKey } from "utils/const";

const PREFIX = "UI_";

export const uiActionType = {
  toggleMenu: createAction(PREFIX + "TOGGLE_MENU")
};

export const uiAction = {
  toggleMenu: () => (dispatch, getState) => {
    const {
      ui: { collapsed }
    } = getState();
    window.sessionStorage.setItem(StorageKey.collapsed, !collapsed);
    dispatch(uiActionType.toggleMenu());
  }
};
