import { configureStore } from "@reduxjs/toolkit";
import { middlewares } from "./middlewares";
import reducer from "./reducers/rootReducer";

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares)
});

export default store;
