import { LoginOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { googleLogout } from "@react-oauth/google";
import { Avatar, Button, Dropdown } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "services";
import styled from "styled-components";
import { StorageKey, Urls } from "utils/const";
import { localStorageHelper } from "utils/localStorageHelper";
import { mixin } from "utils/uiConst";

const StyledName = styled.span`
  @media (${mixin.to.lg}) {
    display: none;
  }
`;

const User = () => {
  const navigate = useNavigate();

  const { currentData } = userService.endpoints.getMySelf.useQueryState();
  const user = currentData;

  const [openMenu, setOpenMenu] = useState(false);

  const onLogout = () => {
    googleLogout();

    localStorageHelper.remove(StorageKey.token);
    localStorageHelper.remove(StorageKey.user);
    window.location.replace(Urls.home);
  };

  const viewProfile = () => {
    setOpenMenu(false);
    navigate(Urls.profile);
  };

  const items = [
    {
      key: "profile",
      label: "Tài khoản",
      onClick: viewProfile,
      icon: <UserOutlined />
    },
    {
      key: "logout",
      label: "Đăng xuất",
      onClick: onLogout,
      icon: <LogoutOutlined />
    }
  ];

  if (!user) {
    return (
      <Button type="link" href={Urls.login} icon={<LoginOutlined />}>
        Đăng nhập
      </Button>
    );
  }

  return (
    <Dropdown menu={{ items }} open={openMenu}>
      <span
        style={{
          display: "flex",
          gap: "3px",
          alignItems: "center",
          cursor: "pointer"
        }}
        onClick={() => setOpenMenu(!openMenu)}
      >
        <Avatar src={user.avatar} icon={<UserOutlined />} />
        <StyledName>{user.fullName || user.userName}</StyledName>
      </span>
    </Dropdown>
  );
};

export default User;
