import styled from "styled-components";

const StyledPaper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 16px;
  min-height: 400px;
`;

const Paper = ({ children }) => {
  return <StyledPaper>{children}</StyledPaper>;
};

export default Paper;
