export const SUCCESS_COLOR = "#52c41a";
export const ERROR_COLOR = "#ff4d4f";
export const DEFAULT_TEXT_COLOR = "#282e91";
export const FONT_FAMILY = "Arial, Helvetica, sans-serif";

export const breakPoint = {
  /** `1600px`. */
  xxl: 1600,
  /** `1200px`. */
  xl: 1200,
  /** `992px`. */
  lg: 992,
  /** `768px`. */
  md: 768,
  /** `576px`. */
  sm: 576,
  /** `0`. */
  xs: 0
};

export const mixin = {
  from: {
    xl: `min-width: ${breakPoint.xl}px`,
    lg: `min-width: ${breakPoint.lg}px`,
    md: `min-width: ${breakPoint.md}px`,
    sm: `min-width: ${breakPoint.sm}px`
  },
  to: {
    xl: `max-width: ${breakPoint.xl - 1}px`,
    lg: `max-width: ${breakPoint.lg - 1}px`,
    md: `max-width: ${breakPoint.md - 1}px`,
    sm: `max-width: ${breakPoint.sm - 1}px`
  },
  between: {
    mdAndXl: `(min-width: ${breakPoint.md}px) and (max-width: ${
      breakPoint.xl - 1
    }px)`
  }
};

export const bodyOverflow = active => {
  if (active) {
    document.body.style.setProperty("overflow", "hidden");
    if (
      window.innerWidth > breakPoint.lg &&
      document.body.scrollHeight > document.documentElement.clientHeight
    ) {
      document.body.style.setProperty("padding-right", "10px");
    }
  } else {
    document.body.removeAttribute("style");
  }
};
