import { localStorageHelper } from "./localStorageHelper";

export const ApiMethod = {
  post: "post",
  get: "get",
  put: "put",
  delete: "delete"
};

export const DefaultMessage = {
  type: null,
  content: null
};

export const MessageType = {
  success: "success",
  error: "error",
  warn: "warn",
  info: "info"
};

export const APP_NAME =
  process.env.REACT_APP_APP_NAME || "Trung tâm Anh ngữ Ulus";

export const PagingConfig = {
  page: 1,
  pageSize: 10,
  options: [5, 10, 20]
};

export const StorageKey = {
  token: "token",
  user: "user",
  collapsed: "collapsed"
};

export const Urls = {
  home: "/",
  /** `/dang-nhap`. */
  login: "/dang-nhap",
  /** `/tin-tuc`. */
  posts: "/tin-tuc",
  /** `/bai-thi`. */
  exams: "/bai-thi",
  /** `/ket-qua-bai-thi`. */
  quizResults: "/ket-qua-bai-thi",
  /** `/thong-tin-tai-khoan`. */
  profile: "/thong-tin-tai-khoan",
  /** `/chinh-sach-bao-mat`. */
  policy: "/chinh-sach-bao-mat",
  /** `/gioi-thieu`. */
  about: "/gioi-thieu",
  /** `/lien-he`. */
  contact: "/lien-he",
  /** `/khoa-hoc`. */
  courses: "/khoa-hoc",
  /** `/huong-dan-xoa-tai-khoan`. */
  deleteAccountGuide: "/huong-dan-xoa-tai-khoan"
};

export const OAuthProvider = {
  facebook: "facebook",
  google: "google"
};

export const Gender = {
  male: 0,
  female: 1,
  other: 2
};

export const Pattern = {
  phone: /^0\d{9}$/,
  userName: /^[a-zA-Z0-9_-]+$/
};

const UploadAccept = {
  image: "image/jpg,image/png,image/jpeg"
};

export const getUploadConfig = () => ({
  accept: UploadAccept.image,
  action: process.env.REACT_APP_API_URL + "/medias",
  headers: {
    Authorization: "Bearer " + localStorageHelper.get(StorageKey.token)
  }
});

export const RefType = {
  user: 1,
  post: 2,
  course: 3,
  quiz: 4
};
