import { List } from "antd";
import { useState } from "react";
import { getKeyAnswer, createMarkup } from "utils";
import { StyledPostWrapper } from "utils/globalStyle";
import Answer from "../answer";
import "./question.css";

const Question = props => {
  const [selectedAnswer, setSelectedAnswer] = useState(undefined);

  const changeAnswerHandler = answer => {
    setSelectedAnswer(answer);
    if (props.onSelectAnswer) {
      // Callback nhận id của question làm tham số.
      props.onSelectAnswer(props.id, answer.id);
    }
  };

  return (
    <div>
      <div className="question">
        <b>{props.sort}.</b>
        <StyledPostWrapper>
          <div dangerouslySetInnerHTML={createMarkup(props.title)} />
        </StyledPostWrapper>
      </div>
      <List
        dataSource={props.answers}
        renderItem={(answer, index) => (
          <List.Item>
            <Answer
              data={answer}
              keyAnswer={getKeyAnswer(index + 1)} // Có trường hợp câu trả lời ngẫu nhiên nên render key phía Client.
              isSelect={selectedAnswer && selectedAnswer.id === answer.id}
              onSelect={changeAnswerHandler}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default Question;
