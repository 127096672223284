import {
  authService,
  courseService,
  postService,
  quizResultService,
  quizService,
  userService
} from "services";

/** Thêm các api middleware tạo từ RTK-Query. */
export const middlewares = [
  authService.middleware,
  courseService.middleware,
  quizService.middleware,
  quizResultService.middleware,
  postService.middleware,
  userService.middleware
];
