import { DatePicker as AntDatePicker } from "antd";
import dayjs from "dayjs";

const dateFormat = "DD/MM/YYYY";

/** Wrapper của `Ant.DatePicker`, dùng `dayjs` để parse `value`. */
const DatePicker = props => {
  const value = props.value ? dayjs(props.value) : null;

  return <AntDatePicker {...props} format={dateFormat} value={value} />;
};

export default DatePicker;
