import { baseApi, baseUrl } from "./baseApiService";
import { createApi } from "@reduxjs/toolkit/query/react";

export const courseService = createApi({
  reducerPath: "course",
  baseQuery: baseApi({ baseUrl }),
  endpoints: builder => ({
    getList: builder.query({
      query: () => ({ endpoint: "courses/compact" })
    }),
    getByUrl: builder.query({
      query: p => ({ endpoint: "courses/" + p.url })
    })
  })
});
