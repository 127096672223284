import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const NotFound = ({ title }) => {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title={title}
      extra={
        <Button type="primary" onClick={() => navigate(-1)}>
          Quay lại
        </Button>
      }
    />
  );
};

NotFound.defaultProps = {
  title: "Không tìm thấy"
};

export default NotFound;
