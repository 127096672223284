import { Button } from "antd";

const LoadMore = ({ onLoadMore, loading }) => (
  <div
    style={{
      textAlign: "center",
      marginTop: 12,
      height: 32,
      lineHeight: "32px"
    }}
  >
    <Button loading={loading} onClick={onLoadMore}>
      Xem thêm
    </Button>
  </div>
);

export default LoadMore;
