import { createReducer } from "@reduxjs/toolkit";
import { uiActionType } from "redux/actions";
import { StorageKey } from "utils/const";

const collapsed = sessionStorage.getItem(StorageKey.collapsed);
const initialState = {
  // Mặc định sẽ là collapsed bởi vì chỉ hiện sidebar khi mobile.
  collapsed: collapsed !== "false"
};

const uiReducer = createReducer(initialState, builder => {
  builder.addCase(uiActionType.toggleMenu, state => {
    state.collapsed = !state.collapsed;
  });
});

export default uiReducer;
