import { BellOutlined } from "@ant-design/icons";
import { Space } from "antd";
import User from "components/commons/user";

const RightHeader = () => {
  return (
    <Space direction="horizontal" align="center" size="middle">
      <BellOutlined />

      <User />
    </Space>
  );
};

export default RightHeader;
