import { useClickOutside, useIsMobile } from "hooks";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiAction } from "redux/actions";
import { StyledMenu, StyledSider } from "./styles";
import { bodyOverflow } from "utils/uiConst";

const Sider = ({ menus }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { collapsed } = useSelector(state => state.ui);

  const ref = useRef(null);

  useEffect(() => {
    if (isMobile && !collapsed) {
      toggleSidebar();
    }
  }, [isMobile]);

  useEffect(() => {
    if (isMobile && !collapsed) {
      bodyOverflow(true);
      return () => bodyOverflow(false);
    }
  }, [isMobile, collapsed]);

  const toggleSidebar = () => {
    dispatch(uiAction.toggleMenu());
  };

  const onClickOutsideSidebar = () => {
    if (!collapsed) {
      toggleSidebar();
    }
  };

  useClickOutside(ref, onClickOutsideSidebar);

  return (
    <StyledSider
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={0}
      theme="light"
      width="100%"
      ref={ref}
    >
      <StyledMenu
        theme="light"
        mode="inline"
        items={menus}
        onSelect={toggleSidebar}
      />
    </StyledSider>
  );
};

export default Sider;
