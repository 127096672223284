import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "antd";
import axios from "axios";
import { useEffect } from "react";
import { authService } from "services";
import { OAuthProvider, StorageKey } from "utils/const";
import { localStorageHelper } from "utils/localStorageHelper";

const GoogleLoginButton = ({ onSuccess }) => {
  const [loginOAuth, loginOAuthResp] = authService.useOauthMutation();

  const loginGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const resp = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );
      const userInfo = resp.data;
      const body = {
        name: userInfo.name,
        email: userInfo.email,
        picture: userInfo.picture,
        id: userInfo.sub,
        emailVerified: userInfo.email_verified,
        provider: OAuthProvider.google
      };
      loginOAuth(body);
    },
    onError: error => console.error("Login Google error", error)
  });

  useEffect(() => {
    if (loginOAuthResp.isSuccess && loginOAuthResp.data) {
      const { token } = loginOAuthResp.data;
      localStorageHelper.set(StorageKey.token, token);

      if (onSuccess) {
        onSuccess();
      }
    }
  }, [loginOAuthResp]);

  return (
    <Button
      type="primary"
      ghost
      htmlType="button"
      onClick={loginGoogle}
      icon={<img src="icons/google.svg" />}
      style={{
        display: "flex",
        gap: "5px",
        alignItems: "center",
        width: "100%",
        justifyContent: "center"
      }}
      loading={loginOAuthResp.isLoading}
    >
      Đăng nhập bằng Google
    </Button>
  );
};

export default GoogleLoginButton;
