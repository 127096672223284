import { Breadcrumb, FloatButton, Layout as AntLayout } from "antd";
import { Backdrop, Loading } from "components/commons";
import { StyledMain, StyledMainInner, StyledMainLayout } from "./styles";
import { useEffect, useMemo, useState } from "react";
import Footer from "components/commons/footer";
import { APP_NAME } from "utils/const";
import { Menus } from "utils/menu";
import Header from "./header";
import Sider from "./sider";
import { useIsMobile } from "hooks";
import { Link, Outlet, useLocation } from "react-router-dom";
import { userService } from "services";
import { RedirectLoginCountdown } from "components/features";

const { Content } = AntLayout;

const Layout = ({ needAuth, children }) => {
  const location = useLocation();
  const isMobile = useIsMobile();

  const getMySelfResult = userService.endpoints.getMySelf.useQueryState();

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    setMenuItems(Menus.sort((a, b) => a.sort - b.sort));
  }, []);

  if (getMySelfResult.isLoading || getMySelfResult.isFetching) {
    return <Loading isLoading />;
  }

  if (needAuth && getMySelfResult.isError) {
    return <RedirectLoginCountdown seconds={3} from={location} />;
  }

  return (
    <AntLayout>
      <Header menus={menuItems} />
      {isMobile && <Sider menus={menuItems} />}

      <StyledMainLayout>
        <Content className="content">
          <StyledMain>
            <StyledMainInner>{children || <Outlet />}</StyledMainInner>
          </StyledMain>
        </Content>

        <Footer />
      </StyledMainLayout>

      <FloatButton.BackTop />
    </AntLayout>
  );
};

export const MainContent = ({
  tabTitle = APP_NAME,
  appendTabTitle = true,
  loading,
  breadcrumbs,
  children
}) => {
  useEffect(() => {
    document.title = tabTitle + (appendTabTitle ? " - Anh ngữ Ulus" : "");
  }, [tabTitle]);

  const breadcrumbsData = useMemo(
    () =>
      breadcrumbs?.map(b => ({
        title: b.href ? <Link to={b.href}>{b.title}</Link> : b.title
      })),
    [breadcrumbs]
  );

  return (
    <>
      {breadcrumbsData?.length > 0 && (
        <Breadcrumb className="breadcrumb" items={breadcrumbsData} />
      )}
      {children}

      <Backdrop loading={loading} />
    </>
  );
};

export default Layout;
