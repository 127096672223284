import { useState, useEffect, useRef } from "react";

export const useCountdown = (seconds = 30) => {
  const [counter, setCounter] = useState(seconds);
  const [restartCounter, setRestartCounter] = useState(0);

  const intervalRef = useRef();
  intervalRef.current = counter;

  useEffect(() => {
    let _timer = setInterval(() => {
      if (intervalRef.current == 0) {
        clearInterval(_timer);
      } else setCounter(intervalRef.current - 1);
    }, 1000);
    return () => clearInterval(_timer);
  }, [restartCounter]);

  const resetCounter = (value = seconds) => {
    setCounter(value);
    setRestartCounter(restartCounter + 1);
  };

  return { counter, resetCounter };
};
