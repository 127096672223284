import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { ApiMethod } from "utils/const";
import { baseApi, baseUrl } from "./baseApiService";

export const quizService = createApi({
  reducerPath: "quiz",
  baseQuery: baseApi({ baseUrl }),
  endpoints: builder => ({
    getList: builder.query({
      query: params => ({ endpoint: "quizzes/for-student", params })
    }),
    joinQuiz: builder.mutation({
      query: data => ({
        endpoint: `quizzes/${data.id}/join`,
        method: ApiMethod.post,
        data
      })
    }),
    submitQuiz: builder.mutation({
      query: data => ({
        endpoint: `quizzes/${data.id}/submit`,
        method: ApiMethod.post,
        data
      })
    }),
    getUnsubmittedQuizzes: builder.query({
      query: () => ({ endpoint: "quizzes/unsubmitted" })
    })
  })
});
