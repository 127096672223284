import { Carousel } from "components/commons";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { postService } from "services";
import styled from "styled-components";
import { Urls } from "utils/const";
import { mixin } from "utils/uiConst";

const StyledBanner = styled.div`
  max-height: 250px;

  .slick-list {
    border-radius: 12px;
    overflow: hidden;
  }

  @media (${mixin.to.md}) {
    max-height: 200px;

    .slick-list {
      border-radius: 0;
    }
  }
`;

const StyledItem = styled.div`
  max-height: 250px;

  img {
    height: 100%;
    width: 100%;
    object-fit: fill;

    @media (${mixin.to.md}) {
      max-height: 200px;
    }
  }
`;

const Banner = () => {
  const [getPosts, getPostsResult] = postService.useLazyGetTopPostsQuery();

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <StyledBanner>
      <Carousel>
        {getPostsResult.currentData?.map(item => (
          <StyledItem key={item.id}>
            <Link to={Urls.posts + "/" + item.url}>
              <picture>
                <source
                  srcSet={item.mediaMobileLink}
                  media="(max-width: 576px)"
                />
                <img alt={item.mediaLink} src={item.mediaLink} />
              </picture>
            </Link>
          </StyledItem>
        ))}
      </Carousel>
    </StyledBanner>
  );
};

export default Banner;
