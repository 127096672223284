import { Radio } from "antd";
import "./answer.css";

const Answer = ({ isSelect, data, onSelect, keyAnswer }) => {
  const onSelectHandler = () => {
    if (onSelect) {
      onSelect(data);
    }
  };

  let className = "answer";
  if (isSelect) {
    className += " selected";
  }

  return (
    <div className={className} onClick={onSelectHandler}>
      <Radio checked={isSelect}></Radio>
      <span>
        <b>{keyAnswer}. </b>
      </span>
      <span>{data.value}</span>
    </div>
  );
};

export default Answer;
