import { baseApi, baseUrl } from "./baseApiService";
import { createApi } from "@reduxjs/toolkit/query/react";

export const postService = createApi({
  reducerPath: "post",
  baseQuery: baseApi({ baseUrl }),
  endpoints: builder => ({
    getLatest: builder.query({
      query: params => ({ endpoint: "posts/latest", params })
    }),
    getDetail: builder.query({
      query: data => ({ endpoint: `posts/${data.id}/detail` })
    }),
    getTopPosts: builder.query({
      query: () => ({ endpoint: "/posts/top-posts" })
    })
  })
});
