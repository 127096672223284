import { Spin } from "antd";

const Loading = ({ isLoading }) => {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Spin spinning={isLoading} />
    </div>
  );
};

export default Loading;
